import * as React from "react";
import { Alert } from "antd";

export function CannotFindIntuneApp() {
  return (
    <Alert
      showIcon={true}
      message="Cannot find Intune Mobile App by SIM Request Id. Please fill in Notes in Intune Mobile App (Cache is 6h)."
      type="error"
    />
  );
}
