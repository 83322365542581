import * as React from "react";
import {
  Button,
  Modal,
  Table,
  Alert,
  notification,
  TabsProps,
  Tabs,
} from "antd";
import { MinusSquareTwoTone, PlusSquareTwoTone } from "@ant-design/icons";
import {
  Country,
  CountryPortfolioActionResult,
  CountryPortfolioManagePayload,
  CountryPortfolioWithCountryAndIntuneAppId,
} from "../ts-models";
import { useEffect, useState } from "react";
import { useParams } from "react-router";
import { useSubmit } from "../generic/glow/actions/use-submit";

import { IntuneAppsRadioButtons } from "./IntuneAppsRadioButtons";
import { CannotFindIntuneApp } from "./CannotFindIntuneApp";

export function CountryManage({
  countriesCanAddOrRemove,
  countriesCanAddOrRemoveIntune,
  countriesAddedIntune,
  setIntervalCounter,
  isAdd,
  disabled,
  intuneAppFound,
  appType,
}: {
  countriesCanAddOrRemove: Country[];
  countriesCanAddOrRemoveIntune: Country[];
  countriesAddedIntune: CountryPortfolioWithCountryAndIntuneAppId[];
  setIntervalCounter: (count: number) => void;
  isAdd: boolean;
  disabled: boolean;
  intuneAppFound: boolean;
  appType: string | null;
}) {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [formState, setFormState] = useState<
    "loading" | "error" | "initial" | "done"
  >("initial");
  const [targetSystem, setTargetSystem] = useState<
    "ConfigMgr" | "Intune" | "none"
  >("none");
  const [submitErrorMessage, setSubmitErrorMessage] = useState<
    string | undefined
  >(undefined);
  const [selectedRows, setSelectedRows] = useState<Country[]>([]);
  const [tabItems, setTabItems] = useState<TabsProps["items"]>([]);
  const [selectedIntuneAppId, setSelectedIntuneAppId] = useState<string>("");

  let handleCancel = () => {
    onSelectChange([], []);
    setSelectedRows([]);
    setTargetSystem("none");
    setIsModalVisible(false);
    setSelectedIntuneAppId("");
  };

  let handleOk = async () => {
    if (selectedRows && selectedRows.length > 0) {
      setFormState("loading");
      let result = await submit({
        targetSystem: targetSystem == "ConfigMgr" ? "ConfigMgr" : "Intune",
        countries: selectedRows,
        intuneAppId: selectedIntuneAppId,
      });
      setFormState("done");
      if (result.ok) {
        setIsModalVisible(false);
        if (targetSystem == "ConfigMgr") {
          notification.success({
            duration: 4,
            message: "Countries are processed in background.",
          });
          setIntervalCounter((selectedRows.length + 1) * 2); // = approximate time to finish request in onprem scriptwrapper
        } else {
          setIntervalCounter(3);
        }
      } else {
        setSubmitErrorMessage(JSON.stringify(result.error));
      }
    }
    setSelectedRows([]);
    setTargetSystem("none");
    setSelectedIntuneAppId("");
  };

  useEffect(() => {
    setSelectedRows([]);
  }, [targetSystem]);

  const { id } = useParams();

  const [submit, validate] = useSubmit<
    CountryPortfolioActionResult,
    CountryPortfolioManagePayload
  >(`/api/country-portfolio/${id}/${isAdd ? "add" : "remove"}`);

  let onSelectChange = (selectedRowKeys: any, selectedRows: Country[]) => {
    setSelectedRows(selectedRows);
  };

  const rowSelection = {
    onChange: onSelectChange,
    getCheckboxProps: (record: Country) => ({
      name: record.displayName || "",
    }),
  };

  useEffect(() => {
    setSelectedRows([]);

    if (appType != "iOS" && appType != "Android") {
      setTabItems([
        {
          key: "Intune",
          label: "Intune",
          children: <></>,
        },
        {
          key: "ConfigMgr",
          label: "ConfigMgr",
          children: <></>,
        },
      ]);
    } else {
      setTabItems([
        {
          key: "Intune",
          label: "Intune",
          children: <></>,
        },
      ]);
      setTargetSystem("Intune");
    }
  }, [appType]);

  const onTabsChange = (key: string) => {
    setTargetSystem(key == "ConfigMgr" ? "ConfigMgr" : "Intune");
    setSelectedIntuneAppId("");
  };

  return (
    <React.Fragment>
      {isAdd ? (
        <Button
          key="1"
          icon={<PlusSquareTwoTone twoToneColor="#52c41a" />}
          size="middle"
          disabled={disabled}
          onClick={() => {
            setIsModalVisible(true);
          }}
        >
          Add country
        </Button>
      ) : (
        <Button
          key="2"
          icon={<MinusSquareTwoTone twoToneColor="#ff4d4f" />}
          size="middle"
          disabled={disabled}
          onClick={() => {
            setIsModalVisible(true);
          }}
        >
          Remove country
        </Button>
      )}
      <Modal
        title={isAdd ? "Add Countries" : "Remove Countries"}
        open={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        okButtonProps={{ loading: formState == "loading" }}
      >
        <Tabs
          activeKey={targetSystem == "none" ? "99" : targetSystem}
          items={tabItems}
          onChange={onTabsChange}
        />

        {targetSystem == "none" && (
          <Alert
            message="Please choose between ConfigMgr or Intune."
            type="info"
            showIcon
          />
        )}
        {submitErrorMessage && (
          <Alert
            message="Error"
            type="error"
            description={submitErrorMessage}
          />
        )}
        {targetSystem == "Intune" && intuneAppFound == false ? (
          <CannotFindIntuneApp />
        ) : (
          targetSystem !== "none" && (
            <>
              {targetSystem != "ConfigMgr" && (
                <IntuneAppsRadioButtons
                  selectedIntuneAppId={selectedIntuneAppId}
                  setSelectedIntuneAppId={setSelectedIntuneAppId}
                />
              )}
              {/* {targetSystem == "Intune" && !selectedIntuneAppId && (
                <Alert
                  message="Please choose a Intune App first."
                  type="info"
                  showIcon
                  style={{ marginTop: 10 }}
                />
              )} */}
              {((targetSystem == "Intune" && selectedIntuneAppId) ||
                targetSystem == "ConfigMgr") && (
                <>
                  <Table<Country>
                    key={targetSystem}
                    rowSelection={{
                      type: "checkbox",
                      ...rowSelection,
                    }}
                    rowKey="id"
                    size="small"
                    columns={[
                      {
                        title: "Name",
                        dataIndex: "displayName",
                        render: (value: any, record: Country) => (
                          <a>{record?.displayName || "n/a"}</a>
                        ),
                      },
                    ]}
                    dataSource={
                      targetSystem === "ConfigMgr"
                        ? countriesCanAddOrRemove
                        : isAdd
                        ? countriesCanAddOrRemoveIntune.filter(
                            (country) =>
                              !countriesAddedIntune.some(
                                (x) =>
                                  x.intuneAppId === selectedIntuneAppId &&
                                  x.country.id === country.id
                              )
                          )
                        : countriesCanAddOrRemoveIntune.filter((country) =>
                            countriesAddedIntune
                              .filter(
                                (x) => x.intuneAppId == selectedIntuneAppId
                              )
                              .find((c) => c.country.id == country.id)
                          )
                    }
                    pagination={false}
                    scroll={{ y: 300 }}
                  />
                </>
              )}
            </>
          )
        )}
      </Modal>
    </React.Fragment>
  );
}
