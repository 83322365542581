import * as React from "react";
import { Alert, Descriptions, Skeleton, Tag, Typography } from "antd";
import {
  CountryPortfolioWithCountryAndIntuneAppId,
  defaultMobileApp,
  MobileApp,
} from "../ts-models";
import { useEffect, useState } from "react";
import { useParams } from "react-router";
import { useData } from "../generic/glow/actions/use-data";
import { AppDetails } from "../operations-intune/AppDetails";
import { CannotFindIntuneApp } from "./CannotFindIntuneApp";

const { Paragraph, Text } = Typography;

export function CountryPortfolioIntuneMobileApp({
  setIntuneAppFound,
  countryPortfolioWithCountryAndIntuneApps,
}: {
  setIntuneAppFound: React.Dispatch<React.SetStateAction<boolean>>;
  countryPortfolioWithCountryAndIntuneApps: CountryPortfolioWithCountryAndIntuneAppId[];
}) {
  const { id } = useParams();
  const [ellipsis, setEllipsis] = useState(true);

  const { data, refetch, status, error } = useData<MobileApp[]>(
    `/api/country-portfolio/${id}/intuneMobileApp`,
    [defaultMobileApp]
  );

  useEffect(() => {
    if (status == "success" && data.length > 0 && data[0].id) {
      setIntuneAppFound(true);
    }
  }, [data, status]);

  return (
    <>
      {status == "loading" && (
        <Skeleton.Button active={true} size={"small"} block={false} />
      )}
      {status == "success" && (
        <>
          {data.length > 0 ? (
            <Descriptions
              size="small"
              column={2}
              bordered={true}

              //bordered={false}
              //layout="vertical"
            >
              {data.map((mobileApp) => (
                <Descriptions.Item
                  label={
                    <AppDetails
                      mobileApp={mobileApp}
                      showFullDisplayName={true}
                    />
                  }
                  labelStyle={{ width: 300 }}
                  span={2}
                >
                  {countryPortfolioWithCountryAndIntuneApps
                    .filter((x) => x.intuneAppId == mobileApp.id)
                    .map((x) => (
                      <Tag>{x.country.isoCode}</Tag>
                    ))}
                </Descriptions.Item>
              ))}
            </Descriptions>
          ) : (
            <CannotFindIntuneApp />
          )}
        </>
      )}
      {error && (
        <Alert
          showIcon={true}
          message={
            <Paragraph
              ellipsis={
                ellipsis ? { rows: 2, expandable: true, symbol: "more" } : false
              }
            >
              Cannot get Intune Mobile App
            </Paragraph>
          }
          type="error"
        />
      )}
    </>
  );
}
