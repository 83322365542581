import * as React from "react";
import { Radio, Space, RadioChangeEvent } from "antd";
import { defaultMobileApp, MobileApp } from "../ts-models";
import { useParams } from "react-router";
import { useData } from "../generic/glow/actions/use-data";
import { LoadingIndicator } from "../generic/loadingIndicator";

export function IntuneAppsRadioButtons({
  setSelectedIntuneAppId,
  selectedIntuneAppId,
}: {
  setSelectedIntuneAppId: React.Dispatch<React.SetStateAction<string>>;
  selectedIntuneAppId: string;
}) {
  const { id } = useParams();

  const { data, refetch, status, error } = useData<MobileApp[]>(
    `/api/country-portfolio/${id}/intuneMobileApp`,
    [defaultMobileApp]
  );

  //when data is loaded, check if there is any data and if is only one item, set it as selected
  React.useEffect(() => {
    if (status == "success" && data.length == 1) {
      setSelectedIntuneAppId(data[0].id || "");
    }
  }, [status]);

  const onChange = (e: RadioChangeEvent) => {
    console.log("radio checked", e.target.value);
    setSelectedIntuneAppId(e.target.value);
  };

  return (
    <>
      {status == "loading" && <LoadingIndicator />}

      {status == "success" && data.length > 1 && (
        <div style={{ marginBottom: "10px" }}>
          <div style={{ marginBottom: "10px", fontWeight: "bold" }}>
            Choose an Intune App:
          </div>
          <Radio.Group onChange={onChange} value={selectedIntuneAppId}>
            <Space direction="vertical">
              {data.map((p) => (
                <Radio value={p.id}>{p.displayName}</Radio>
              ))}
            </Space>
          </Radio.Group>
        </div>
      )}
    </>
  );
}
