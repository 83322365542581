import * as React from "react";
import { Button, Descriptions, Alert, Tag, Skeleton } from "antd";
import { PageHeader } from "@ant-design/pro-layout";
import {
  CaretLeftOutlined,
  CodeOutlined,
  GlobalOutlined,
  WindowsOutlined,
} from "@ant-design/icons";
import {
  CountryPortfolioDetailViewDto,
  defaultCountryPortfolioDetailViewDto,
  GenericIntegration,
  MobileAppIntegration,
} from "../ts-models";
import { GetField } from "../generic/field";

import { useEffect, useState } from "react";

import { useNavigate, useParams } from "react-router";

import styled from "styled-components";
import { ActionHistoryTable } from "../generic/ActionHistoryTable";
import { Link } from "react-router-dom";
import { useData } from "../generic/glow/actions/use-data";
import { AppTypeIcon } from "./list";
import { Switch, Typography } from "antd";
import { CountryManage } from "./CountryManage";
import { CountryPortfolioIntuneMobileApp } from "./CountryPortfolioIntuneMobileApp";

export const Field = GetField<MobileAppIntegration>();

export function CountryPortfolioDetailView() {
  const { id } = useParams();
  const navigate = useNavigate();
  const [intervalNumber, setIntervalNumber] = useState<number | null>(null);
  const [intervalCounter, setIntervalCounter] = useState<number>(0);
  const [intervalState, setIntervalState] = useState<"enabled" | "disabled">(
    "disabled"
  );
  const [intuneAppFound, setIntuneAppFound] = useState<boolean>(false);

  const {
    data: entity,
    refetch: refetchDetailview,
    status,
  } = useData<CountryPortfolioDetailViewDto>(
    `/api/country-portfolio/${id}`,
    defaultCountryPortfolioDetailViewDto
  );

  useEffect(() => {
    if (intervalCounter > 0) {
      const asyncFunc = async () => {
        await delay(intervalCounter * 1000);
        refetchDetailview();
        setIntervalCounter(0);
      };

      asyncFunc();
    }
  }, [intervalCounter]);

  return (
    <div>
      <HeaderTop
        title={
          <div>
            <span>
              <GlobalOutlined /> Country Portfolio
            </span>
            <Button
              icon={<CaretLeftOutlined />}
              onClick={() => navigate("..")}
              style={{ marginLeft: 10, marginRight: 10 }}
              size="small"
            />{" "}
            {entity.displayName}
          </div>
        }
        extra={[
          <Button
            size="middle"
            loading={intervalCounter > 0}
            onClick={() => setIntervalCounter(1)}
          >
            Refresh
          </Button>,
          <CountryManage
            key="add"
            countriesCanAddOrRemove={entity.countriesCanAdd}
            countriesCanAddOrRemoveIntune={entity.countriesCanAddIntune}
            countriesAddedIntune={
              entity.countryPortfolioWithCountryAndIntuneApps
            }
            disabled={
              entity.countriesCanAdd.length == 0 &&
              entity.countriesCanAddIntune.length == 0
            }
            setIntervalCounter={setIntervalCounter}
            isAdd={true}
            intuneAppFound={intuneAppFound}
            appType={entity.genericIntegration.type}
          />,
          <CountryManage
            key="remove"
            countriesCanAddOrRemove={entity.countriesCanRemove}
            countriesCanAddOrRemoveIntune={entity.countriesCanRemoveIntune}
            countriesAddedIntune={
              entity.countryPortfolioWithCountryAndIntuneApps
            }
            disabled={
              entity.countriesCanRemove.length == 0 &&
              entity.countriesCanRemoveIntune.length == 0
            }
            setIntervalCounter={setIntervalCounter}
            isAdd={false}
            intuneAppFound={intuneAppFound}
            appType={entity.genericIntegration.type}
          />,
        ]}
      ></HeaderTop>
      <Container>
        <Descriptions
          size="small"
          column={2}
          bordered={true}

          //bordered={false}
          //layout="vertical"
        >
          <Descriptions.Item
            label={`Application details`}
            span={2}
            labelStyle={{ width: 300 }}
          >
            {status == "loading" && (
              <Skeleton.Button active={true} size={"small"} block={false} />
            )}
            {entity.genericIntegration.type == "Win10ClassicIntegration" ? (
              <span>
                <Link
                  to={`/integrations/win10-classic/${entity.genericIntegration.id}`}
                >
                  {entity.displayName}
                </Link>
              </span>
            ) : entity.genericIntegration.type == "iOS" ? (
              <span>
                <Link
                  to={`/integrations/mobile-app/ios/${entity.genericIntegration.id}`}
                >
                  {entity.displayName}
                </Link>
              </span>
            ) : entity.genericIntegration.type == "Android" ? (
              <span>
                <Link
                  to={`/integrations/mobile-app/android/${entity.genericIntegration.id}`}
                >
                  {entity.displayName}
                </Link>
              </span>
            ) : (
              <span>
                <Link
                  to={`/integrations/win10-app/${entity.genericIntegration.id}`}
                >
                  {entity.displayName}
                </Link>
              </span>
            )}
          </Descriptions.Item>
          <Descriptions.Item label={`Type`} span={2}>
            {status == "loading" && (
              <Skeleton.Button active={true} size={"small"} block={false} />
            )}
            {status == "success" && (
              <AppTypeIcon genericIntegration={entity.genericIntegration} />
            )}
          </Descriptions.Item>
          <Descriptions.Item label={`RequestId`} span={2}>
            {status == "loading" && (
              <Skeleton.Button active={true} size={"small"} block={false} />
            )}
            {entity.genericIntegration.requestID}
          </Descriptions.Item>
          <Descriptions.Item
            label={`Assigned countries ConfigMgr (${entity.countries.length} of ${entity.countriesCountOverall})`}
            span={2}
          >
            {entity.countries.map((country) => (
              <Tag>{country.isoCode}</Tag>
            ))}
          </Descriptions.Item>

          {/* <Descriptions.Item label={`Intune App(s)`} span={2}>
      
          </Descriptions.Item> */}

          {/* <Descriptions.Item
            label={`Assigned countries Intune (${entity.countriesIntune.length} of ${entity.countriesCountOverall})`}
            span={2}
          >
            {entity.countriesIntune.map((country) => (
              <Tag>{country.country.isoCode}</Tag>
            ))}
          </Descriptions.Item> */}
          <Descriptions.Item label="App Owner" span={2}>
            {entity.genericIntegration?.appOwner?.displayName || "unknown"}
          </Descriptions.Item>
        </Descriptions>
        <h3 style={{ marginTop: 20 }}>Intune Apps</h3>
        <CountryPortfolioIntuneMobileApp
          setIntuneAppFound={setIntuneAppFound}
          countryPortfolioWithCountryAndIntuneApps={
            entity.countryPortfolioWithCountryAndIntuneApps
          }
        />
        <h3 style={{ marginTop: 20 }}>Requests history</h3>

        <ActionHistoryTable actionHistory={entity.actionHistory} />
      </Container>
    </div>
  );
}

const Header = styled(PageHeader)`
  padding: 0px;
  margin-bottom: 20px;
`;

const Container = styled.div`
  padding: 20px;
`;

const HeaderTop = styled(PageHeader)`
  background: #fff;
`;

const delay = (ms: number | undefined) =>
  new Promise((res) => setTimeout(res, ms));
